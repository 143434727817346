@import 'variables';
// @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
/* Ajout des animations */
@keyframes slideDownFadeIn {
	0% {
		opacity: 0;
		transform: translateY(20%);
	}
	100% {
		opacity: 1;
		transform: translateY(0.2rem);
	}
}

@keyframes slideUpFadeOut {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(-20%);
	}
}

.header {
	height: 60vh;
	background-color: $background;
	background-position: center; /* Centre l'image de fond */
	background-size: cover; /* S'assure que l'image couvre tout le container */
	background-repeat: no-repeat; /* Empêche la répétition de l'image */
	background-attachment: fixed; /* Crée l'effet de parallaxe en fixant l'image */

	&.parallax {
		background-position: center 0;
	}

	.header__cover {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		backdrop-filter: blur(1px);
	}

	nav {
		z-index: 2;
		left: 0;
		top: 0;
		width: 100%;
		height: 3.5rem;
		padding-bottom: 1rem;
		background-color: rgba($color: #ffff, $alpha: 0.5);
		transition: background-color 0.8s ease-in-out;
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.nav__img {
			height: 3rem;
			margin-top: 0.5rem;
		}

		ul {
			display: flex;
			justify-content: center;
		}

		li {
			margin: 0 1rem;

			a {
				// border-radius: 0 10px 0 0;
				padding: 0.2rem;
				transition: color 0.3s ease-in-out;
			}

			a:hover,
			.openMenu {
				color: white;
				background-color: rgba($color: $background, $alpha: 1);
				text-decoration: underline;
			}
		}

		.navComponent__subMenu {
			display: none;
			position: absolute;
			transform: translateY(15%);
			border: solid rgba($color: $background, $alpha: 1);
			border-radius: 0 10px 10px 10px;
			padding: 0;
			list-style-type: none;
			animation: slideUpFadeOut 0.4s ease-in-out forwards;
			background-color: $background;
			backdrop-filter: blur(5px);

			li {
				margin: 0;
				white-space: nowrap;

				a {
					cursor: pointer;
					border-radius: 10px;
					text-decoration: none;
					color: white; // Color spécifique pour les liens dans subMenu
					padding: 8px;
					display: block; // Pour que le lien prenne tout l'espace du li
					transition: background-color 0.3s ease-in-out;
				}

				a:hover {
					color: black;
					background-color: white; // Background hover pour les liens dans subMenu
				}
			}
		}
		.navComponent__subMenu-open {
			display: block;
			transform: translateY(4%);
			animation: slideDownFadeIn 0.4s ease-in-out forwards;
		}
	}

	.header__nav-scroll {
		background-color: $background;
		opacity: 0.97;

		a {
			color: white;
		}
	}

	h1 {
		display: flex;
		flex-direction: column;

		span {
			color: $main;
			font-size: 2rem;
			font-family: 'Verdana', sans-serif;
		}
		#title1 {
			margin-left: 7rem;
		}
		#title3 {
			margin-left: 3rem;
		}
	}
}
