@import 'variables';

.carrousel {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.carrousel__button {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 3.5rem;
		border-radius: 20px 0 0 20px;
		transition: background-color 0.3s ease-in-out;

		img {
			height: 5rem;
			// cursor: pointer;
			color: black;
		}
	}

	.carrousel__button:hover {
		background-color: rgba($color: $background, $alpha: 0.5);
	}

	.left {
		left: 0;
	}

	.right {
		right: 0;
		transform: rotate(180deg);
	}

	.carrousel__img {
		display: block;
		width: 100%;
		height: 100%;
		max-height: 25rem;
		border-radius: 20px;
		object-fit: contain;
	}

	.carrousel__index {
		position: absolute;
		bottom: 10px;
		font-size: 0.75rem;
		color: white;
	}
}
