///////////Variables/////////////////
$main-light: #ffbb1e;
$main: #e39f00;
$seconde: #d7c998;
$terciaire: #a3232b;
$background: #842e1b;

// $main-light: #ffbb1e;
// $main: #e39f00;
// $main-light: rgb(255, 187, 30);
// $main: rgb(227, 159, 0);
// $purple-background: #470038;
