@import 'variables';

.archives {
	// min-height: 90vh;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	.archives__portrait {
		width: 80%;
		margin-bottom: 2rem;
		padding: 2rem;
		border: 2px solid black;
		border-radius: 10px;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
		background-color: white;

		img {
			width: 7.75rem;
			height: 7.75rem;
			margin: 0 1rem 1rem 0;
			object-fit: cover;
			border-radius: 50%;
			float: left;
			shape-outside: margin-box;
		}

		div {
			margin-left: 2rem;
			line-height: 1.3;

			h2 {
				color: $background;
				text-decoration: underline;
			}

			p {
				text-align: justify;
			}
		}
	}
}

.archives__nav {
	width: 90%;
	margin: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	ul {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

// .archives__nav--scroll {
// }

// .archives__collapses {
// 	height: 5.5rem;
// 	border-bottom: solid 3px black;
// 	display: flex;
// 	flex-direction: row;
// 	flex-wrap: wrap;
// 	justify-content: center;
// }

.archives__skills-container {
	width: 100%;
	padding: 1rem;
	// background: #f7f9fc;
	// border-radius: 10px;
	// box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

	.archives__skills-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 0.75rem;

		.archives__skills-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.5rem 1rem;
			background: white;
			border: 1px solid #e0e6ed;
			border-radius: 10px;
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
			transition: all 0.3s ease;
			color: #333;
			cursor: default;

			&:hover {
				transform: translateY(-3px);
				box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
			}

			span {
				margin: 0 0.25rem;
			}

			// &::before {
			// 	content: ''; // Ajouter une icône ou remplacer par une image
			// 	margin-right: 0.5rem;
			// 	color: #007bff;
			// }
		}
	}
}
