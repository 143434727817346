@import 'variables';

.presentationCard-left-init {
	width: 85vw;
	height: auto;
	margin: 2rem 0;
	padding: 2rem;
	border: 2px solid black;
	// border-radius: 20px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
	transform: translateX(-90vw);
	transition: transform 1.3s ease-in-out;
	background-color: white;

	img {
		width: 7.75rem;
		height: 7.75rem;
		margin: 0 1rem 1rem 2rem;
		object-fit: cover;
		border-radius: 50%;
		float: left;
		shape-outside: margin-box;
	}

	div {
		margin-left: 2rem;
		line-height: 1.3;

		h2 {
			color: $background;
			text-decoration: underline;
		}

		p {
			text-align: justify;
		}
	}
}

.presentationCard-left {
	background-color: white;
	transform: translateX(-7%);
}

.presentationCard-element {
	display: none;
	opacity: 0;
	transform: translateY(-20%);
	transition: opacity 0.5s ease, transform 0.5s ease;
	height: 20rem;
	position: relative;
	border: solid 2px rgb(74, 53, 152);
	border-radius: 20px;
	background-color: white;
	overflow: hidden;
	pointer-events: none;

	button {
		width: 100%;
		height: 100%;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
			border-radius: inherit;
			transition: transform 0.3s ease-in-out;
		}

		img:hover {
			transform: scale(1.1);
		}

		div {
			position: absolute;
			width: 100%;
			height: 5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			box-sizing: border-box;
			bottom: 0px;
			left: 0px;
			padding: 0.75rem;
			text-align: left;
			color: $background;
			background-color: rgba($color: $main-light, $alpha: 0.6);
			backdrop-filter: blur(5px);
			pointer-events: none;
		}
	}
	button:focus {
		border: solid 4px rgb(120, 89, 231); /* Facultatif, pour une bordure supplémentaire */
		border-radius: inherit;
	}
}

.presentationCard-element:hover {
	border: solid 2px rgb(120, 89, 231);
}

.presentationCard-element-inline {
	display: inline;
}

.presentationCard-element-show {
	opacity: 1;
	transform: translateY(0);
	cursor: pointer;
	pointer-events: auto;
}

.custom-overlay {
	background-color: rgba(0, 0, 0, 0.5); // Couleur de fond de la superposition
	z-index: 9;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}

.custom-modal {
	position: fixed;
	overflow: scroll;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 95vw;
	height: 70vh;
	background: white;
	padding: 20px;
	border-radius: 8px;
	outline: none;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

	// Styles pour le contenu de la modale
	.modal-content {
		// margin: 2rem;

		h4 {
			color: #333;
		}

		.close-modal-button {
			position: fixed;
			right: 1rem;
			top: 1rem;
			height: 2rem;
			width: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #007bff;
			color: white;
			// border: none;
			border-radius: 50%;
			padding: 10px;
			cursor: pointer;
			&:hover {
				background: #0056b3;
			}
		}

		h2 {
			margin-top: 0;
		}

		.modal-content-background {
			border-radius: 10px;
			padding: 1rem;
			background: url('../assets/title_background.png');
			// background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}

		.modal-content-modale {
			margin-bottom: 1rem;
			border: solid 2px black;
			border-radius: 20px;
			box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.4);
			background-color: white;
		}

		.modal-content-texte {
			border-radius: 20px;
			padding: 1rem;
			// color: $main;
			background-color: white;
			text-align: justify;

			.modal-content-text-p1 {
				margin-bottom: 10px;
				padding: 20px;
				background-color: #f8f9fa; // Couleur de fond légèrement grise
				border-radius: 8px;
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Ombre douce pour l'effet de profondeur
				color: #333; // Couleur du texte
			}

			.presentationCard-element__detail {
				padding: 20px;
				background-color: #f8f9fa; // Couleur de fond légèrement grise
				border-radius: 8px;
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Ombre douce pour l'effet de profondeur
				color: #333; // Couleur du texte

				span {
					display: block;
					font-weight: bold;
					margin-bottom: 10px;
					color: #2c3e50; // Couleur du texte du titre
				}

				ul {
					list-style-type: disc;
					margin-left: 20px;
					margin-bottom: 20px;

					li {
						margin-bottom: 5px;
						line-height: 1.5;
						color: #555; // Couleur du texte des éléments de la liste
					}
				}

				a {
					color: #007bff; // Couleur du lien
					text-decoration: none;
					font-weight: bold;
					transition: color 0.3s ease;

					&:hover {
						color: #0056b3; // Couleur du lien au survol
						text-decoration: underline;
					}
				}
			}
		}
	}
}
