$primary-color: #007bff;
$secondary-color: #6c757d;
$success-color: #28a745;
$error-color: #dc3545;
$background-color: #f8f9fa;
$input-border-color: #ced4da;

// Mixin pour les transitions
@mixin transition($property, $duration: 0.3s, $timing: ease-in-out) {
	transition: $property $duration $timing;
}

.contact {
	max-width: 600px;
	margin: 2rem auto;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: $background-color;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

	.contact__form {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	h2 {
		text-align: center;
		margin-bottom: 1.5rem;
		color: $primary-color;
	}

	div {
		margin-bottom: 1rem;
		width: 100%;

		label {
			display: block;
			margin-bottom: 0.5rem;
			font-weight: bold;
			color: $secondary-color;
		}

		input,
		textarea {
			width: 100%;
			box-sizing: border-box;
			padding: 0.75rem;
			border: 1px solid $input-border-color;
			border-radius: 4px;
			font-size: 1rem;
			background-color: #fff;
			color: #495057;

			@include transition(all);

			&:focus {
				border-color: $primary-color;
				outline: none;
				box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.25);
			}
		}

		textarea {
			min-height: 150px;
			resize: vertical;
		}
	}

	// Button styling
	button {
		display: inline-block;
		width: 100%;
		padding: 0.75rem;
		font-size: 1.25rem;
		font-weight: bold;
		text-align: center;
		color: #fff;
		background-color: $primary-color;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);

		@include transition(background-color, 0.2s);

		&:hover {
			background-color: darken($primary-color, 10%);
		}
	}

	// Success/Error messages
	p {
		margin-top: 1rem;
		text-align: center;
		font-weight: bold;

		&.success {
			color: $success-color;
		}

		&.error {
			color: $error-color;
		}
	}
}
