@import 'variables';

.main {
	z-index: 1;
	overflow-x: hidden;
	position: relative;
	min-height: 80vh;
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-end;
	background: url('../assets/main_background.png');
	background-position: center; /* Centre l'image de fond */
	background-size: cover; /* S'assure que l'image couvre tout le container */
	background-repeat: no-repeat; /* Empêche la répétition de l'image */
	background-attachment: fixed; /* Crée l'effet de parallaxe en fixant l'image */
	border-top: solid 3px black;
	background-position: center bottom;
	transition: background-position 0.3s ease-out; /* Transition pour un effet progressif */

	.main__presentationCard {
		margin: 2rem;
		box-sizing: content-box;
		// width: 100%;
		display: grid;
		gap: 2rem;

		.main__presentationCard__title {
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: solid 2px rgb(74, 53, 152);
			border-radius: 20px;
			background-color: rgb(74, 53, 152);
			background: center / cover no-repeat
				url('../assets/title_background.png');

			cursor: pointer;

			h3 {
				padding: 0.5rem 1rem;
				font-size: 1.5rem;
				color: $main-light;
			}
			img {
				height: 6rem;
				transform: rotate(180deg);
				transition: transform 0.6s;
			}
		}

		.main__presentationCard__title:hover {
			border: solid 2px rgb(120, 89, 231);
		}

		.main__presentationCard__title-open {
			img {
				transition: transform 0.6s;
				transform: rotate(270deg);
			}
		}
	}

	.projects {
		background-color: #f7f7f7;
		padding: 2rem 2rem 1rem 2rem;
		border-radius: 20px;
		max-width: 90vw;
		margin: 2rem auto;
		cursor: default;

		h2 {
			text-align: center;
			font-size: 1.5rem;
			color: #333;
			margin-bottom: 2rem;
		}

		.projects__list {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;

			li {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;
				width: 80vw;
				height: 5rem;
				padding: 0.25rem;
				border-radius: 10px;
				// text-align: center;
				background-color: #ffffff;
				transition: 0.3s ease;
			}

			li:hover {
				transform: translateX(2px) translateY(-2px);
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
			}
		}
	}

	.project-steps {
		background-color: #f7f7f7;
		padding: 2rem;
		border-radius: 20px;
		max-width: 90vw;
		margin: 3rem auto;

		&__title {
			text-align: center;
			font-size: 2rem;
			color: #333;
			margin-bottom: 2rem;
		}

		&__list {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
			gap: 1.5rem;
		}
	}

	.project-step {
		background-color: #ffffff;
		min-height: 13rem;
		padding: 1.5rem;
		border-radius: 1rem;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
		text-align: center;
		transition: transform 0.3s ease, box-shadow 0.3s ease;
		cursor: default;

		&:hover {
			transform: translateY(-0.5rem);
			box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
		}

		&__number {
			background-color: $main-light;
			color: #333;
			width: 3rem;
			height: 3rem;
			line-height: 3rem;
			border-radius: 50%;
			font-size: 1.5rem;
			margin: 0 auto 1rem;
		}

		&__title {
			font-size: 1.25rem;
			color: $background;
			margin-bottom: 1rem;
		}

		&__description {
			font-size: 0.75rem;
			color: #666;
			line-height: 1.5;
			text-align: justify;
		}
		li {
			font-size: 0.75rem;
			text-align: left;
			color: #666;
			line-height: 1.5;
		}
	}

	.rocket {
		z-index: 2;
		width: 5rem;
		position: fixed;
		right: 2rem;
		bottom: 30vh;
		cursor: pointer;
		opacity: 1;
		transform: translateY(0); /* Position normale */
		transition: opacity 0.5s ease, transform 0.5s ease;
	}

	.rocket--hidden {
		opacity: 0;
		transform: translateY(
			-20px
		); /* Déplace l'image légèrement vers le bas */
		transition: opacity 0.5s ease, transform 0.5s ease;
	}
}

.main::before {
	content: '';
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3); /* Adjust the opacity for darkness */
	backdrop-filter: blur(2px); /* Adjust the blur intensity */
}

.main::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(
		circle,
		rgba(0, 0, 0, 0) 0%,
		rgb(0, 0, 0) 100%
	); /* Vignette effect */
	z-index: -2;
}

/* Ensure the main content is above the background layers */
.main > * {
	position: relative;
	z-index: 1;
}
