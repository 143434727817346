.footer {
	// position: absolute;
	// bottom: 0;
	// width: 100%;
	// height: 20vh;

	background-color: black;
	text-align: center;
	color: white;

	a {
		color: white;
	}

	.footer-container {
		display: flex;
		flex-direction: column;

		.legal-info,
		.contact-info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 0.5rem;

			li {
				font-size: 0.5rem;
			}
		}

		.social-links {
			display: block;

			h4 {
				margin-top: 0.5rem;
			}

			li {
				// display: flex;
				// justify-content: space-between;
				margin-top: 0.5rem;

				img {
					width: 1rem;
					height: 1rem;
					margin-right: 0.5rem;
				}
			}
		}
	}

	.footer-bottom {
		margin-top: 0.5rem;
		color: white;
		p {
			font-size: 0.75rem;
		}
	}
}
