* {
	margin: 0;
	padding: 0;
	font-family: 'Verdana', sans-serif;
	font-size: 12px;
	text-decoration: none;
	list-style: none;
}

body {
	min-height: 100vh;
	button {
		background: none;
		border: none;
		cursor: pointer;
	}
}

//Breakpoint little screen
@media (min-width: 350px) {
	* {
		font-size: 16px;
	}
}
//Breakpoint smartphone -> tab
@media (min-width: 768px) {
	* {
		font-size: 18px;
	}

	.header {
		height: 100vh;

		nav {
			flex-direction: row;
			justify-content: space-between;
			padding: 0 2rem;
			box-sizing: border-box;

			.nav__img {
				margin-top: 0;
			}
		}

		h1 {
			span {
				font-size: 3rem;
			}
		}
	}

	.footer {
		.footer-container {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
		}
	}

	.main {
		flex-direction: column;
		width: 100%;

		.main__presentationCard {
			grid-template-columns: repeat(1, 1fr);

			.main__presentationCard__title {
				min-width: 100%;
			}
		}

		.projects {
			.projects__list {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				gap: 0.5rem;

				li {
					display: flex;
					align-items: center;
					width: 20rem;
				}
			}
		}

		.project-steps {
			.project-steps__list {
				display: grid;
				grid-template-columns: repeat(2, minmax(250px, 1fr));
				gap: 1.5rem;
			}
		}
	}

	.custom-modal {
		width: 70vw;

		.modal-content {
			margin: 2rem;
		}
	}

	.archives {
		flex-direction: column;

		.archives__portrait {
			margin-bottom: 0;
			margin-top: 2rem;
		}
	}

	// .collapse-archives {
	// 	width: 95%;
	// }

	// .collapse-archives {
	// 	.collapse__head-archives {
	// 		.collapse__arrow-archives {
	// 			transform: rotate(90deg);
	// 		}
	// 		.collapse__arrow-open-archives {
	// 			transform: rotate(180deg);
	// 		}
	// 	}

	// 	.collapse__element-archives {
	// 		left: 15.5rem;
	// 		top: 90vh;
	// 	}
	// }
}

// @media (min-width: 992px) {
// 	* {
// 		font-size: 20px;
// 	}

// 	.main {
// 		.main__presentationCard {
// 			.main__presentationCard__title {
// 				height: 16rem;
// 			}
// 		}
// 	}
// }

//Breakpoint tab -> desktop
@media (min-width: 1200px) {
	* {
		font-size: 20px;
	}

	.header {
		h1 {
			span {
				font-size: 3.5rem;
			}
		}
	}

	.main {
		.main__presentationCard {
			grid-template-columns: repeat(3, 1fr);

			.main__presentationCard__title {
				height: 16rem;
				img {
					transform: rotate(0deg);
					transition: transform 0.6s;
				}
			}

			.main__presentationCard__title-open {
				img {
					transition: transform 0.6s;
					transform: rotate(180deg);
				}
			}
		}

		.presentationCard-element {
			height: 16rem;
			transform: translateX(-20%);
		}

		.presentationCard-element-show {
			transform: translateX(0);
		}

		.project-steps {
			.project-steps__list {
				display: grid;
				grid-template-columns: repeat(3, minmax(250px, 1fr));
				gap: 1.5rem;
			}
		}
	}
}

//Breakpoint desktop++
@media (min-width: 1400px) {
	* {
		font-size: 24px;
	}
}
