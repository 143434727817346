@import 'variables';

// .collapse {
// }
.collapse-archives {
	width: 100%;
	margin-top: 0.5rem;

	.collapse__head-archives {
		width: 100%;
		margin-bottom: 0.5rem;
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		background: linear-gradient(to right, $main, $main-light);

		h2 {
			text-align: left;
			margin-left: 0.2rem;
			color: white;
		}

		.collapse__arrow-archives {
			width: 2rem;
			transform: rotate(180deg);
			transition: transform 0.6s;
		}
		.collapse__arrow-open-archives {
			width: 2rem;
			transform: rotate(270deg);
			transition: transform 0.6s;
		}
	}

	.collapse__head-open-archives {
		background-color: $main-light;
		// transform: translateY(-0.2rem);
	}

	.collapse__element-archives {
		left: 0;
		right: 0;
		// margin: 2rem;
		z-index: 1;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		border: 1px solid #ddd;
		padding: 0 10px;
		display: flex;
		justify-content: center;
		gap: 1rem;
		flex-wrap: wrap;

		.collapse-item {
			position: relative;
			display: inline-block;
			margin: 0.5rem;
			text-align: center;

			&__image {
				width: 4rem; // Taille de l'image
				height: 4rem;
				object-fit: contain;
				display: block;
				margin: 0 auto;
			}

			&__title {
				margin-top: 0.25rem;
				font-size: 1rem;
			}

			&__tooltip {
				visibility: hidden;
				background-color: rgba(0, 0, 0, 0.75);
				color: #fff;
				text-align: center;
				border-radius: 10px;
				padding: 0.25rem;
				position: absolute;
				z-index: 1;
				bottom: 115%; // Position au-dessus de l'image
				// left: 50%;
				transform: translateX(-50%);
				white-space: normal;
				opacity: 0;
				transition: opacity 0.3s ease-in-out;
				width: 15rem; // Limite la largeur de l'info-bulle
				max-height: 6rem;
				overflow: hidden;
				text-overflow: ellipsis; // Ajoute des points de suspension si le texte déborde
				word-wrap: break-word;

				/* Pointe de l'info-bulle */
				&::after {
					content: '';
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50%);
					border-width: 5px;
					border-style: solid;
					border-color: rgba(0, 0, 0, 0.75) transparent transparent
						transparent;
				}
			}

			&:hover .collapse-item__tooltip {
				visibility: visible;
				opacity: 1;
			}

			.collapse-item__progress-container {
				margin-top: 0.5rem;
				height: 8px;
				background-color: #fde6e6;
				border-radius: 4px;
				overflow: hidden;
			}

			.collapse-item__progress-bar {
				height: 100%;
				background-color: #4caf50; // Couleur de la barre de progression
			}
		}
	}
}

// .collapse-archives {
// 	max-width: 13rem;
// 	margin: 0 0.5rem 0.5rem 0;

// 	.collapse__head-archives {
// 		width: 100%;
// 		height: 2.5rem;
// 		border-radius: 0 20px 0 0;
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: space-between;
// 		align-items: center;
// 		background-color: $main;
// 		box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);

// 		h2 {
// 			text-align: left;
// 			margin-left: 0.2rem;
// 		}

// 		.collapse__arrow-archives {
// 			width: 2rem;
// 			transform: rotate(180deg);
// 			transition: transform 0.6s;
// 		}
// 		.collapse__arrow-open-archives {
// 			width: 2rem;
// 			transform: rotate(270deg);
// 			transition: transform 0.6s;
// 		}
// 	}

// 	// .collapse__head-archives:hover {
// 	// 	transform: translateY(-0.2rem);
// 	// 	transition: transform 0.2s;
// 	// }

// 	.collapse__head-open-archives {
// 		background-color: $main-light;
// 		transform: translateY(-0.2rem);
// 	}

// 	.collapse__element-archives {
// 		position: absolute;
// 		left: 0;
// 		right: 0;
// 		top: 65vh;
// 		margin: 0 2rem;
// 		z-index: 1;
// 		overflow: auto;
// 		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// 		border: 1px solid #ddd;
// 		padding: 0 10px;
// 	}

// 	.collapse__element-archives {
// 		max-height: 90vh;
// 	}
// }
